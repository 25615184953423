import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Deload Week`}</em></p>
    <p>{`DB Bench Press & DB Rows 3×12\\@approx 60% 1RM`}</p>
    <p>{`then,`}</p>
    <p>{`15:00 AMRAP of:`}</p>
    <p>{`15-KBS (53/35)`}</p>
    <p>{`12-Ring DIps`}</p>
    <p>{`9-K2E’s`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Reminder: Submit your score for 19.4 by 8:00pm tonight!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our new class schedule will starts today!  Evening CrossFit classes
will now be offered Monday-Friday at 4:00, 5:00 & 6:00pm.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our new Ville Bootcamp starts this Tues-Friday at 9:30am.  The rest
of this month is free for non members so spread the word if you know of
anyone that may want to try it out. This class is also included in your
unlimited membership. `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`Our next Mobility class will be this Tuesday from 11:30-12:00.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      